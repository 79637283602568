import React from "react"

export default function HeroBadgeComponent() {
  return (
    <div className="flex flex-col items-center gap-10 rounded-md sm:flex-row">
      {/* <!-- base size --> */}
      <span className="inline-flex items-center justify-center gap-1 rounded bg-emerald-500 px-1.5 text-sm text-white ">
        3<span className="sr-only"> new emails </span>
      </span>

      {/* <!-- sm size --> */}
      <span className="inline-flex items-center justify-center rounded bg-pink-500 px-1.5 text-xs text-white">
        3<span className="sr-only"> new emails </span>
      </span>

      {/* <!-- xs size --> */}
      <span className="inline-flex p-1 rounded-full bg-emerald-500">
        <span className="sr-only"> 3 new emails </span>
      </span>
    </div>
  )
}

import React, { useState } from "react"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

import BadgesRoundedPreview from "../../../library/components/badge/react/_preview/rounded/basic"
import BadgesRoundedIconPreview from "../../../library/components/badge/react/_preview/rounded/icon"

import BadgesRoundedFullPreview from "../../../library/components/badge/react/_preview/rounded-full/basic"
import BadgesRoundedFullIconPreview from "../../../library/components/badge/react/_preview/rounded-full/icon"

import HeroBadgeComponent from "../../../library/components/badge/react/_preview/heroBadgeComponent"
// *** Badges Rounded *** //
// Base
const BadgeRoundedBaseBasicJsx = require("!!raw-loader!../../../library/components/badge/react/rounded/base/basic.jsx")
const BadgeRoundedBaseBasicHTML = require("!!raw-loader!../../../library/components/badge/html/rounded/base/basic.html")

const BadgeRoundedBaseIconJsx = require("!!raw-loader!../../../library/components/badge/react/rounded/base/icon.jsx")
const BadgeRoundedBaseIconHTML = require("!!raw-loader!../../../library/components/badge/html/rounded/base/icon.html")
// Sm
const BadgeRoundedSmBasicJsx = require("!!raw-loader!../../../library/components/badge/react/rounded/sm/basic.jsx")
const BadgeRoundedSmBasicHTML = require("!!raw-loader!../../../library/components/badge/html/rounded/sm/basic.html")

const BadgeRoundedSmIconJsx = require("!!raw-loader!../../../library/components/badge/react/rounded/sm/icon.jsx")
const BadgeRoundedSmIconHTML = require("!!raw-loader!../../../library/components/badge/html/rounded/sm/icon.html")
// Xs
const BadgeRoundedXsBasicJsx = require("!!raw-loader!../../../library/components/badge/react/rounded/xs/basic.jsx")
const BadgeRoundedXsBasicHTML = require("!!raw-loader!../../../library/components/badge/html/rounded/xs/basic.html")

// *** Badges Rounded Full *** //
// Base
const BadgeRoundedFullBaseBasicJsx = require("!!raw-loader!../../../library/components/badge/react/rounded-full/base/basic.jsx")
const BadgeRoundedFullBaseBasicHTML = require("!!raw-loader!../../../library/components/badge/html/rounded-full/base/basic.html")

const BadgeRoundedFullBaseIconJsx = require("!!raw-loader!../../../library/components/badge/react/rounded-full/base/icon.jsx")
const BadgeRoundedFullBaseIconHTML = require("!!raw-loader!../../../library/components/badge/html/rounded-full/base/icon.html")
// Sm
const BadgeRoundedFullSmBasicJsx = require("!!raw-loader!../../../library/components/badge/react/rounded-full/sm/basic.jsx")
const BadgeRoundedFullSmBasicHTML = require("!!raw-loader!../../../library/components/badge/html/rounded-full/sm/basic.html")

const BadgeRoundedFullSmIconJsx = require("!!raw-loader!../../../library/components/badge/react/rounded-full/sm/icon.jsx")
const BadgeRoundedFullSmIconHTML = require("!!raw-loader!../../../library/components/badge/html/rounded-full/sm/icon.html")
// Xs
const BadgeRoundedFullXsBasicJsx = require("!!raw-loader!../../../library/components/badge/react/rounded-full/xs/basic.jsx")
const BadgeRoundedFullXsBasicHTML = require("!!raw-loader!../../../library/components/badge/html/rounded-full/xs/basic.html")

export default function BadgesPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "BadgesRounded",
      title: "Basic badge",
      active_tab: 1,
    },
    {
      component_name: "BadgesRoundedIcon",
      title: "Basic with icon",
      active_tab: 1,
    },
    {
      component_name: "BadgesRoundedFull",
      title: "Full rounded",
      active_tab: 1,
    },
    {
      component_name: "BadgesRoundedFullIcon",
      title: "Full rounded with icon",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Badges - WindUI Component Library"
        ogtitle="Tailwind CSS Badges - WindUI Component Library"
        description="A badge component is a visual indicator that draws attention to an element and enhances it with additional information. Built with Tailwind CSS by WindUI."
        ogdescription="A badge component is a visual indicator that draws attention to an element and enhances it with additional information. Built with Tailwind CSS by WindUI."
        url="components/badges/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Badges, Badges, Badge Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Badges</h1>
          <p>
            A badge is a visual indicator that draws attention to an interface
            element. It enhances the component to which it is attached with
            additional information.
          </p>

          {/* Badges Preview Section */}

          <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <HeroBadgeComponent />
            </section>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Badges Type Basic Section*/}
          <h3 id="BadgesRounded">Basic Badge</h3>
          <p>With rounded corners in 3 different sizes.</p>

          <PreviewBlock
            id="BadgesRounded"
            HtmlComponent={BadgeRoundedBaseBasicHTML.default}
            JsxComponent={BadgeRoundedBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <BadgesRoundedPreview
                BadgeRoundedBase={
                  activeTabs[0].active_tab === 1
                    ? BadgeRoundedBaseBasicHTML.default
                    : BadgeRoundedBaseBasicJsx.default
                }
                BadgeRoundedSm={
                  activeTabs[0].active_tab === 1
                    ? BadgeRoundedSmBasicHTML.default
                    : BadgeRoundedSmBasicJsx.default
                }
                BadgeRoundedXs={
                  activeTabs[0].active_tab === 1
                    ? BadgeRoundedXsBasicHTML.default
                    : BadgeRoundedXsBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Badges Type Basic Icon Section*/}
          <h3 id="BadgesRoundedIcon">Basic Badge with Icon</h3>
          <p>With rounded corners and assistive icon in 3 different sizes. </p>

          <PreviewBlock
            id="BadgesRoundedIcon"
            HtmlComponent={BadgeRoundedBaseIconHTML.default}
            JsxComponent={BadgeRoundedBaseIconJsx.default}
            getActiveTabs={getActiveTabs}
            className="not-prose"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <BadgesRoundedIconPreview
                BadgeRoundedBaseIcon={
                  activeTabs[1].active_tab === 1
                    ? BadgeRoundedBaseIconHTML.default
                    : BadgeRoundedBaseIconJsx.default
                }
                BadgeRoundedSmIcon={
                  activeTabs[1].active_tab === 1
                    ? BadgeRoundedSmIconHTML.default
                    : BadgeRoundedSmIconJsx.default
                }
                BadgeRoundedXs={
                  activeTabs[1].active_tab === 1
                    ? BadgeRoundedXsBasicHTML.default
                    : BadgeRoundedXsBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Badges Type Rounded Section */}
          <h3 id="variations-subheading-3">Rounded Badge</h3>
          <p>With fully rounded corners in 3 different sizes. </p>

          <PreviewBlock
            id="BadgesRoundedFull"
            HtmlComponent={BadgeRoundedFullBaseBasicHTML.default}
            JsxComponent={BadgeRoundedFullBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <BadgesRoundedFullPreview
                BadgeRoundedFullBase={
                  activeTabs[2].active_tab === 1
                    ? BadgeRoundedFullBaseBasicHTML.default
                    : BadgeRoundedFullBaseBasicJsx.default
                }
                BadgeRoundedFullSm={
                  activeTabs[2].active_tab === 1
                    ? BadgeRoundedFullSmBasicHTML.default
                    : BadgeRoundedFullSmBasicJsx.default
                }
                BadgeRoundedFullXs={
                  activeTabs[2].active_tab === 1
                    ? BadgeRoundedFullXsBasicHTML.default
                    : BadgeRoundedFullXsBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Badges Type Rounded with Icon Section */}
          <h3 id="BadgesRoundedFullIcon">Rounded Badge with Icon</h3>
          <p>
            With fully rounded corners and assistive icon in 3 different sizes.
          </p>

          <PreviewBlock
            id="BadgesRoundedFullIcon"
            HtmlComponent={BadgeRoundedFullBaseIconHTML.default}
            JsxComponent={BadgeRoundedFullBaseIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <BadgesRoundedFullIconPreview
                BadgeRoundedFullBaseIcon={
                  activeTabs[3].active_tab === 1
                    ? BadgeRoundedFullBaseIconHTML.default
                    : BadgeRoundedFullBaseIconJsx.default
                }
                BadgeRoundedFullSmIcon={
                  activeTabs[3].active_tab === 1
                    ? BadgeRoundedFullSmIconHTML.default
                    : BadgeRoundedFullSmIconJsx.default
                }
                BadgeRoundedFullXs={
                  activeTabs[3].active_tab === 1
                    ? BadgeRoundedFullXsBasicHTML.default
                    : BadgeRoundedFullXsBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            A Badge is a small numerical value or status descriptor for UI
            elements. Use the Badge Component Symbol to draw attention to
            another interface element or to display a notification (ie unread
            messages next to en email icon). It enhances the component to which
            it is attached with additional information.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Badges scale in 3 sizes from base to small and extra small and come
            both in normal or full rounded shapes.
          </p>
          <ul>
            <li>
              <strong>base:</strong> Height 1.25rem (20px), font-size 0.875rem
              (14px), icon size 1rem (16px)
            </li>
            <li>
              <strong>sm:</strong> Height 1rem (16px), font-size 0.75rem (12px),
              icon size 1rem (16px)
            </li>
            <li>
              <strong>xs:</strong> Height 0.25rem (4px)
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            A screen reader only inner text, provides information on the badge's
            notification functionality. On the variations with the icon, an{" "}
            <code>aria-hidden</code> attribute is added to completely exclude
            them from the accessibility tree since the icons are purely
            decorative.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}

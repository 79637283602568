import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function BadgeRoundedFullAllIconPreview(props) {
  const {
    BadgeRoundedFullBaseIcon,
    BadgeRoundedFullSmIcon,
    BadgeRoundedFullXs,
  } = props

  return (
    <>
      {/*<!-- Component: All rounded full icon badges --> */}
      <div className="flex h-36 w-96 items-center justify-center gap-10">
        <CopyComponent
          copyToClipboardCode={BadgeRoundedFullBaseIcon}
          componentName="BadgeRoundedFullBaseIcon"
        >
          {/*  <!-- Component: Rounded full icon base sized badge --> */}
          <span className="inline-flex items-center justify-center gap-1 rounded-full bg-emerald-500 px-1.5 text-sm text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-03 desc-03"
              role="graphics-symbol"
            >
              <title id="title-03">User Icon</title>
              <desc id="desc-03">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
            7<span className="sr-only"> new emails</span>
          </span>
          {/*  <!-- End Rounded full icon base sized badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={BadgeRoundedFullSmIcon}
          componentName="BadgeRoundedFullSmIcon"
        >
          {/*  <!-- Component: Rounded full icon sm sized badge --> */}
          <span className="inline-flex items-center justify-center gap-1 rounded-full bg-emerald-500 px-1.5 text-xs text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3 w-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-04 desc-04"
              role="graphics-symbol"
            >
              <title id="title-04">User Icon</title>
              <desc id="desc-04">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
            7<span className="sr-only"> new emails</span>
          </span>
          {/*  <!-- End Rounded full icon sm sized badge  --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={BadgeRoundedFullXs}
          componentName="BadgeRoundedFullXs"
        >
          {/*  <!-- Component: Rounded full icon xs sized badge --> */}
          <span className="inline-flex rounded-full bg-emerald-500 p-1">
            <span className="sr-only">7 new emails</span>
          </span>
          {/*  <!-- End Rounded full icon xs sized badge --> */}
        </CopyComponent>
      </div>
      {/*<!-- End All rounded full icon badges --> */}
    </>
  )
}

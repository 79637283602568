import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function BadgeRoundedFullAllBasicPreview(props) {
  const { BadgeRoundedFullBase, BadgeRoundedFullSm, BadgeRoundedFullXs } = props

  return (
    <>
      {/*<!-- Component: All rounded full basic badges --> */}
      <div className="flex items-center justify-center gap-10 h-36 w-96">
        <CopyComponent
          copyToClipboardCode={BadgeRoundedFullBase}
          componentName="BadgeRoundedFullBase"
        >
          {/*  <!-- Component: Rounded full basic base sized badge --> */}
          <span className="inline-flex items-center justify-center gap-1 rounded-full bg-emerald-500 px-1.5 text-sm text-white">
            7<span className="sr-only"> new emails</span>
          </span>
          {/*  <!-- End Rounded full basic base sized badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={BadgeRoundedFullSm}
          componentName="BadgeRoundedFullSm"
        >
          {/*  <!-- Component: Rounded full basic sm sized badge --> */}
          <span className="inline-flex items-center justify-center rounded-full bg-emerald-500 px-1.5 text-xs text-white">
            7<span className="sr-only"> new emails</span>
          </span>
          {/*  <!-- End Rounded full basic sm sized badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={BadgeRoundedFullXs}
          componentName="BadgeRoundedFullXs"
        >
          {/*  <!-- Component: Rounded full basic xs sized badge --> */}
          <span className="inline-flex p-1 rounded-full bg-emerald-500">
            <span className="sr-only">7 new emails</span>
          </span>
          {/*  <!-- End Rounded full basic xs sized badge --> */}
        </CopyComponent>
      </div>
      {/*<!-- End All rounded full basic badges --> */}
    </>
  )
}

import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function BadgeRoundedAllBasicPreview(props) {
  const { BadgeRoundedBase, BadgeRoundedSm, BadgeRoundedXs } = props

  return (
    <>
      {/*<!-- Component: All rounded basic badges --> */}
      <div className="flex items-center justify-center gap-10 h-36 w-96">
        <CopyComponent
          copyToClipboardCode={BadgeRoundedBase}
          componentName="BadgeRoundedBase"
        >
          {/*  <!-- Component: Rounded basic base sized badge --> */}
          <span className="inline-flex items-center justify-center gap-1 rounded bg-emerald-500 px-1.5 text-sm text-white">
            7<span className="sr-only"> new emails</span>
          </span>
          {/*  <!-- End Rounded basic base sized badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={BadgeRoundedSm}
          componentName="BadgeRoundedSm"
        >
          {/*  <!-- Component: Rounded basic sm sized badge --> */}
          <span className="inline-flex items-center justify-center rounded bg-emerald-500 px-1.5 text-xs text-white">
            7<span className="sr-only"> new emails</span>
          </span>
          {/*  <!-- End Rounded basic sm sized badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={BadgeRoundedXs}
          componentName="BadgeRoundedXs"
        >
          {/*  <!-- Component: Rounded basic xs sized badge --> */}
          <span className="inline-flex p-1 rounded-full bg-emerald-500">
            <span className="sr-only">7 new emails</span>
          </span>
          {/*  <!-- End Rounded basic xs sized badge --> */}
        </CopyComponent>
      </div>
      {/*<!-- End All rounded basic badges --> */}
    </>
  )
}
